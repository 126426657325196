<template>
    <section class="px-4">
        <div class="row mx-0 mb-4 mt-3">
            <div class="col-12 text-general text-center f-600 mt-5" style="font-size:37px;">
                Verificar código
            </div>
        </div>
        <div class="row mx-0 mb-4 mt-3">
            <div class="col-12 text-general text-center">
                <p class="f-15">
                    Te enviamos un código al
                </p>
                <p class="text-leeche f-17 f-600">
                    {{ getUsuarioModel.usuario }}
                </p>
            </div>
        </div>
        <div class="row mx-0 mb-4 mt-3">
            <div class="row mx-0 justify-center">
                <div class="col px-0 mx-1">
                    <el-input
                    ref="cod1"
                    v-model.number="cod1"
                    maxlength="1"
                    minlength="1"
                    class="w-100"
                    @input.self="next(2)"
                    />
                </div>
                <div class="col px-0 mx-1">
                    <el-input
                    ref="cod2"
                    v-model.number="cod2"
                    maxlength="1"
                    minlength="1"
                    class="w-100"
                    @input.self="next(3)"
                    @keyup.native.delete="back(1)"
                    />
                </div>
                <div class="col px-0 mx-1">
                    <el-input
                    ref="cod3"
                    v-model.number="cod3"
                    maxlength="1"
                    minlength="1"
                    class="w-100"
                    @input.self="next(4)"
                    @keyup.native.delete="back(2)"
                    />
                </div>
                <div class="col px-0 mx-1">
                    <el-input
                    ref="cod4"
                    v-model.number="cod4"
                    maxlength="1"
                    minlength="1"
                    class="w-100"
                    @input.self="next(5)"
                    @keyup.native.delete="back(3)"
                    />
                </div>
                <div class="col px-0 mx-1">
                    <el-input
                    ref="cod5"
                    v-model.number="cod5"
                    maxlength="1"
                    minlength="1"
                    class="w-100"
                    @input.self="next(6)"
                    @keyup.native.delete="back(4)"
                    />
                </div>
                <div class="col px-0 mx-1">
                    <el-input
                    ref="cod6"
                    v-model.number="cod6"
                    maxlength="1"
                    minlength="1"
                    class="w-100"
                    @keyup.native.delete="back(5)"
                    />
                </div>
            </div>
        </div>
        <div class="row mx-0 mb-4 mt-3">
            <div class="col-12 text-general text-center">
                <p v-if="!timeOut" class="f-15">¿No te llegó nada? <span class="text-green cr-pointer" @click="resendCode()">Reenviar código</span></p>
                <p v-else>
                    {{ segundos }} segundos
                </p>
            </div>
        </div>
        <div class="row mx-0 mb-4 mt-3">
            <div class="col-12 text-center">
                <div class="col-12 px-0">
                    <button
                    class="btn-general btn-block p-1 py-2 d-flex d-middle-center text-white"
                    @click="confirmCode()"
                    >
                        Verificar
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import authService from "~/services/auth";
import registroService from '~/services/registro/registro'
const segundosDefault = 90
export default {
    data(){
        return {
            cod1: '',
            cod2: '',
            cod3: '',
            cod4: '',
            cod5: '',
            cod6: '',
            timer: null,
            interval: null,
            timeOut: false,
            segundos: segundosDefault
        }
    },
    computed: {
        ...mapGetters({
            getUsuarioModel: 'register/getUsuarioModel',
            getPhoneNumber: 'register/getPhoneNumber',
        }),
        allCode(){
            return `${this.cod1}${this.cod2}${this.cod3}${this.cod4}${this.cod5}${this.cod6}`
        },
        codeComplete(){
            return [this.cod1,this.cod2, this.cod3, this.cod4, this.cod5, this.cod6].every((el) => {
                return !_.isEmpty(`${el}`.trim())
            })
        },
    },
    methods: {
        async confirmCode(){
            try {
                if (this.codeComplete){
                    const p =  await registroService.verificarCode(this.allCode)

                    const { usuario: telefono, ...rest } = this.getUsuarioModel

                    const obj = {
                        ...rest,
                        telefono
                    }

                    const { data } = await authService.registrarFirebase(obj)

                    if(data.message){
                        this.loginTienda(data.token, data.usuario)
                    }
                }
            } catch (e){
                switch (e.code){
                case 'auth/invalid-verification-code':
                    this.notificacion('Error', 'El código que introdujo no es el correcto', 'error')
                    break;

                default:
                    this.error_catch(e)

                }
            }
        },
        async resendTemporalCode(){
            const datos = await registroService.registrar(this.getPhoneNumber)
        },
        async loginTienda(token, usuario){
            this.$store.commit('auth/ALMACENAR_TOKEN', token)
            this.$store.commit('auth/ALMACENAR_USUARIO', usuario)
            await this.$store.dispatch('auth/consultarRoles')
            // this.$refs.modalCodigoVerificacion.toggle();

            this.$router.push({ name: 'registrado' })

        },
        back(number){
            if(this.$refs[`cod${number}`].value){
                this.$nextTick(function(){
                    this.$refs[`cod${number}`].focus()
                })
            }
        },
        next(number){
            this.$nextTick(function(){
                if(this.$refs[`cod${number - 1}`].value != "" && number > 1){
                    this.$refs[`cod${number}`].focus()
                }
            })
        },
        async resendCode(){
            try {
                if (this.interval || this.timer){
                    return
                }
                this.interval = setInterval(() => {
                    this.segundos--;
                }, 1000)
                this.timer = setTimeout(() => {
                    this.timeOut = false
                    this.segundos = segundosDefault
                    clearTimeout(this.timer)
                    clearInterval(this.interval)
                    this.timer = null
                    this.interval = null
                }, segundosDefault * 1000);

                this.timeOut = true
                this.resendTemporalCode()

            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.text-green{
    color: #28D07B !important
}
</style>
